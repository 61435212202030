import { ForFamilyMembers } from "./components/ForFamilyMembers";
import { Faqs } from "./components/Faqs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Pricing } from "./components/Pricing";
import { WorksInternationally } from "./components/WorksInternationally";
import LoadingSpinner from "~/components/loading-spinner";
import { SecondaryFeatures } from "~/marketing-site/components/SecondaryFeatures";
import { ForSeniors } from "~/marketing-site/components/ForSeniors";
import React from "react";
import { OurStory } from "~/marketing-site/components/OurStory";
import { json, LoaderArgsWithContext, redirect, TypedResponse } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { FreeTrialForWellCheckBrandedSiteConfig } from "~/FreeTrialForWellCheckBrandedSiteConfig";

export async function loader({ context }: LoaderArgsWithContext) : Promise<TypedResponse<{freeTrialConfig: FreeTrialForWellCheckBrandedSiteConfig}>>{
  const marketingSiteDisabledForWhitelabelSite = !context.requestScopedData.whiteLabelSiteViewModel?.marketingSiteEnabled;
  if(marketingSiteDisabledForWhitelabelSite){
    return redirect("/login");
  }

  return json({freeTrialConfig: context.freeTrialEnabledForWellCheckBrandedConfig});
}

export default function Home() {
  const loaderData = useLoaderData<typeof loader>()
  return (
    <>
      <Header />
      <LoadingSpinner />
      <main>
        <Hero freeTrialEnabled={loaderData.freeTrialConfig.freeTrialEnabled}/>
        <SecondaryFeatures/>
        {/*<PrimaryFeatures />*/}
        <WorksInternationally freeTrialEnabled={loaderData.freeTrialConfig.freeTrialEnabled} />
        <ForFamilyMembers freeTrialEnabled={loaderData.freeTrialConfig.freeTrialEnabled}/>
        <ForSeniors freeTrialEnabled={loaderData.freeTrialConfig.freeTrialEnabled}/>
        {/*<CallToAction />*/}
        {/*<Testimonials />*/}
        <Pricing />
        <OurStory/>
        <Faqs freeTrialEnabled={loaderData.freeTrialConfig.freeTrialEnabled} />
      </main>
      <Footer />
      {/* <InitAnonymousIntercom/> */}
    </>
  );
}
