"use client";

import { Container } from "./Container";
import { AU, CA, GB, IN, SG, US } from "country-flag-icons/react/3x2";
import { SignUpButton } from "~/marketing-site/components/SignUpButton";
import { FreeTrialForWellCheckBrandedSiteConfig } from "~/FreeTrialForWellCheckBrandedSiteConfig";

export function WorksInternationally(params: FreeTrialForWellCheckBrandedSiteConfig) {
  return (
    <section
        id="international-support"
        aria-label="WellCheck works internationally"
      className="pb-14 sm:pb-20 lg:pb-32 "
    >
      <div
        className="pt-20 sm:pt-32 "
      >
        <Container>
          <div className="mx-auto max-w-2xl md:text-center">
            <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              WellCheck works internationally
            </h2>
            <p className="mt-4 text-md tracking-tight text-slate-700">
              We support the USA, Canada, India, the UK, Australia, and Singapore.
            </p>
            <p className="mt-4 text-md tracking-tight text-slate-700">
              WellCheck works internationally so if you live in a different country and find it hard to always
              check on your aging parent at a convenient time, WellCheck has you covered. For example, if you're in the US and your parent is in India, WellCheck just works!
            </p>
            <div className="flex items-center justify-between mt-5 pt-2 pb-2 pl-2 pr-2 max-w-96 mx-auto">
              <US title="United States" className="w-10 drop-shadow-md" />
              <CA title="Canada" className="w-10 drop-shadow-md"></CA>
              <IN title="India" className="w-10 drop-shadow-md"></IN>
              <GB title="Great Britain" className="w-10 drop-shadow-md" />
              <AU title="Australia" className="w-10 drop-shadow-md"></AU>
              <SG title="Singapore" className="w-10 drop-shadow-md"></SG>
            </div>

            <SignUpButton freeTrialEnabled={params.freeTrialEnabled} className="mt-10"/>
            <p className="text-xs italic pt-2 text-slate-700">No credit card required</p>
            <div className="text-slate-700 italic, text-xs pt-5">
              For Indian phone numbers, rather than sending text messages, we send Whatsapp messages due to automated
              text message restrictions in India and the popularity of Whatsapp.
            </div>
          </div>
        </Container>
      </div>


    </section>
  )
    ;
}
