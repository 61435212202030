"use client";

import { Container } from "./Container";
import { SignUpButton } from "~/marketing-site/components/SignUpButton";
import { FreeTrialForWellCheckBrandedSiteConfig } from "~/FreeTrialForWellCheckBrandedSiteConfig";

export function ForSeniors(params: FreeTrialForWellCheckBrandedSiteConfig) {
  return (
    <section
      id="secondary-features"
      aria-label="WellCheck is for seniors"
      className="pb-14 sm:pb-20 lg:pb-32 "
    >
      <div
        className="pt-20 sm:pt-32 "
      >
        <Container>
          <div className="mx-auto max-w-2xl md:text-center">
            <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
              For Seniors Living Independently
            </h2>
            <p className="mt-4 text-md tracking-tight text-slate-700">
              WellCheck checks in every day to make sure you’re alright. If you live alone, it's nice to have the peace
              of mind that if something happens to you, your friends and family will be alerted and can come check on
              you. It also gives your loved ones the comfort to know that even if they're busy and can't call you every
              day, that you're okay.
            </p>
            <SignUpButton freeTrialEnabled={params.freeTrialEnabled} className="mt-10"/>
            <p className="text-xs italic pt-2 text-slate-700">No credit card required for trial</p>

          </div>
        </Container>
      </div>
    </section>
  )
    ;
}
